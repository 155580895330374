import axios from 'axios'

export default {

  registerUser(payload) {
    return axios.post('users/admin/register', payload)
  },

  getUser(params){
    return axios.get(`/users/admin/${params.userId}`)
  },

  updateUser(payload){
    return axios.patch('/users/admin/update', payload)
  },
  deleteUser(payload){
    return axios.delete(`/users/admin`, {data: payload})
  }

}
