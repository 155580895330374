<template lang="pug">
  v-container(fluid)

    v-row(dense)
      v-col
        v-tabs(v-model="active_tab" icons-and-text background-color="#4DC5C1" dark centered fixed-tabs slider-color="buttons" show-arrows)

          v-tab(v-for="tab of tabs" :key="tab.index") {{ tab.name }}
            v-icon {{ tab.icon }}

        v-tabs-items(v-model="active_tab")

          v-tab-item(v-for="tab of tabs" :key="tab.index")
            v-row
              v-col
                component(:is="tab.component")


</template>
<script>

import Event from './Event'
import Bank from './Bank'
import EventTransactions from './EventTransactions'

  export default{
    props: ['activeTab', 'eventId'],
    components: {
      Event,
      Bank,
      EventTransactions
    },
    data: () => ({
      active_tab: null,
      tabs: [
        { index: 0, name: 'Evento', icon: 'mdi-baby', component: 'Event'},
        { index: 1, name: 'Banco', icon: 'mdi-bank', component: 'Bank'},
        { index: 2, name: 'Transações', icon: 'mdi-cash-multiple', component: 'EventTransactions'}
      ]
    }),
    mounted(){
      this.active_tab = this.activeTab
    }
  }
</script>
