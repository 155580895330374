<template lang="pug">
  v-container(fluid)
    v-row(justify="center")
      v-col(cols="8")
        v-alert(border="left" type="info") Você não tem permissão para acessar essa página.
    v-row(align="center" justify="center")
      v-img(alt="iFraldas" contain :src="`${imgPath}/site/imgs/mascote.png`" transition="scale-transition" max-width="200")
</template>

<script>
  export default{

    data(){
      return{
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET
      }
    },
    methods:{

    },

    mounted(){

    },
    computed:{

    }
  }
</script>
<style>
  .v-main__wrap{
    background-image: none;
  }
</style>
