import api from '@/services/api/gifts'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  gifts: state => state.gifts,
  totalGifts: state => state.totalGifts,
  gift: state => state.gift,
  gId: state => state.gId,
}

const actions = {
  getGifts({ commit }, payload) {
    console.log('getGifts payload: '+JSON.stringify(payload,null,2))
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getGifts(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.GIFTS, response.data.docs)
            commit(types.TOTAL_GIFTS, response.data.totalDocs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getGift({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getGift(payload)
        .then(response => {

          console.log('getGift response: '+JSON.stringify(response,null,2))

          if (response.status === 200) {
            commit(types.GIFT, response.data.docs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          console.log('getGift error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  updateGift({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .updateGift(payload)
        .then(response => {
          //console.log('updateEvent response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          //console.log('getEvent error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  registerGift({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .registerGift(payload)
        .then(response => {
          console.log('registerGift response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            commit(types.GID, response.data._id)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          //console.log('registerGift error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },
  deleteGift({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .deleteGift(payload)
        .then(response => {

          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.DELETED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          console.log('delete error: '+JSON.stringify(error,null,2))
          handleError(error, commit, reject)
        })
    })
  },

}

const mutations = {
  [types.GIFTS](state, value) {
    state.gifts = value
  },
  [types.TOTAL_GIFTS](state, value) {
    state.totalGifts = value
  },
  [types.GIFT](state, value) {
    state.gift = value
  },
  [types.GID](state, value) {
    state.gId = value
  }
}

const state = {
  gifts: [],
  totalGifts: 0,
  gift: [],
  gId: null
}

export default {
  state,
  getters,
  actions,
  mutations
}
