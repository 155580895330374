<template lang="pug" src="./Codes.pug"></template>

<script>

  import { mapActions } from 'vuex'
  import Vue from 'vue'
  import find from 'lodash/find'
  Vue.prototype.find = find

  export default{
    props: ['partnerName'],
    data () {
      return {
        code: '',
        codeRule: {description: 'Recursos Premium grátis', val: true},
        codeRules: [{description: 'Recursos Premium grátis', val: true}],
        siteUrl: process.env.VUE_APP_URL,
        ambiente: process.env,
        urlCopied: false,
        rules: {
          required: value => !!value || 'Campo obrigatório'
        },
        updateId: null,
        active: true,
        modalDate: false,
        
        validUntil: new Date().toISOString().substr(0, 10),
        

        dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
        minDate: new Date().toISOString().slice(0,10),
        validTime: '23:59',

        comissionType: 'percent',
        commissionValue: '',
        commissionUnit: '%'
      }
    },
    computed: {

      headers(){
        return [
          { text: 'Código', value: 'code' },
          { text: 'Regra', value: 'rules' },
          { text: 'Validade', value: 'expires' },
          { text: 'Ações', value: 'actions' }
        ]
      },

      codes(){
        return this.$store.state.partners.codes
      },
      total(){
        return this.$store.state.partners.totalCodes
      },
      dateSuggested() {
        const today = new Date()
        var suggest = new Date(today.getFullYear(), today.getMonth() + 3, today.getDate()+1)
        return suggest.toISOString().slice(0,10)
      },
    },
    methods:{
      ...mapActions(['registerCode', 'getCodes', 'updateCode']),

      requiredComission: value => value !== null && value !== undefined && value !== '' && (value === 0 || !isNaN(value)) || 'Campo obrigatório',
      maxCommission: value => value <= 7.99 || 'O valor máximo deve ser 7.99',

      setUnit(){
        switch(this.comissionType) {
          case 'fixed': this.comissionUnit = 'R$'
            break
          default: this.comissionUnit = '%'
        }
      },

      copyCodeUrl(str){
        var self = this
        navigator.clipboard.writeText(str).then(function() {
          self.urlCopied = true
        }, function(err) {
          console.error('Erro ao copiar o código QR: ', err);
        })
      },

      partnerId(){
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        return urlParams.get('partnerId')
      },

      editCode(item){
        console.log( JSON.stringify( item,null,2 ) )
        this.code = item.code
        this.comissionType = item.rules.promoter.commissionType
        this.commissionValue = item.rules.promoter.commissionValue*100
        this.dateFormatted = this.formatDate(new Date(item.expires).toISOString().substr(0, 10))
        this.active = item.active
        this.updateId = item._id
      },

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      async save(){

        if(this.$refs.form.validate()){

          var date = new Date(`${this.validUntil}T${this.validTime}Z`)

          let commissionVal = parseFloat(this.commissionValue.replace(',', '.'))
          if( this.comissionType == 'percent' ){ commissionVal = commissionVal/100 }

          const payload = {
            user: this.partnerId(),
            code: this.code,
            expires: date,
            active: this.active,
            rules: {
              user: {
                premiumFree: this.codeRule.val
              },
              promoter: {
                commissionType: this.comissionType,
                commissionValue: commissionVal
              }
            }
          }

          if(this.updateId){
            payload._id = this.updateId
            await this.updateCode(payload)
          }else{
            await this.registerCode(payload)
          }

          this.getCodes({ _id: this.partnerId() })
        }
      },

      alpha() {
        this.showLinkWarning = true
        this.code = this.string_to_slug(this.code)
      },

      string_to_slug (str) {
        str = str.toLowerCase();
        // remove accents, swap ñ for n, etc
        const from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
        const to = "aaaaaaeeeeiiiioooouuuunc------";

        for (let i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
        }

        return str
            .replace(/[^a-z0-9 -]/g, "-") // remove invalid chars
            .replace(/\s+/g, "-") // collapse whitespace and replace by -
            .replace(/-+/g, "-") // collapse dashes
            .replace(/^-+/, "") // trim - from start of text
            .replace(/-+$/, "-") // trim - from end of text
            .replace(/-/g, "-");
      },
    },
    mounted(){
      this.dateFormatted = this.formatDate(this.dateSuggested)
      this.getCodes({ _id: this.partnerId() })
    },
    watch: {
      validUntil () {
        this.dateFormatted = this.formatDate(this.validUntil)
      }
    }
  }
</script>
