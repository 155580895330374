<template lang="pug" src="./Bank.pug">

</template>
<script>
import { mapActions } from 'vuex'
import { mask } from 'vue-the-mask'
  export default{

    directives: {mask},

    data: () => ({
      valid: true,
      value: true,
      rules: {
        required: value => !!value || 'Campo obrigatório',
        twoWords: value => (!!value && (/(\w.+\s).+/).test(value)) || 'Digite o nome completo',
        // twoWords: value => (!!value && (/\w+\s+\w/).test(value)) || 'Digite o nome completo',
        //twoWords: value => (!!value && (/^((\b[A-zÀ-ú']{2,40}\b)\s*){2,}$/).test(value)) || 'Digite seu nome completo'
      },
      bankList: [
        // {banco: '001 – Banco do Brasil S.A.', cod: '001'},
        // {banco: '033 – Banco Santander (Brasil) S.A.', cod: '033'},
        // {banco: '104 – Caixa Econômica Federal', cod: '104'},
        // {banco: '237 – Banco Bradesco S.A.', cod: '237'},
        // {banco: '341 – Banco Itaú S.A.', cod: '341'},
        // {banco: '356 – Banco Real S.A. (antigo)', cod: '356'},
        // {banco: '389 – Banco Mercantil do Brasil S.A.', cod: '389'},
        // {banco: '399 – HSBC Bank Brasil S.A. – Banco Múltiplo', cod: '399'},
        // {banco: '422 – Banco Safra S.A.', cod: '422'},
        // {banco: '453 – Banco Rural S.A.', cod: '453'},
        // {banco: '633 – Banco Rendimento S.A.', cod: '633'},
        // {banco: '652 – Itaú Unibanco Holding S.A.', cod: '652'}, //principais bancos primeiro
      ],
      name: '',
      cpf: '',
      celular: '',
      stateList: [
        {nome: 'Acre', sigla: 'AC'},
        {nome: 'Alagoas', sigla: 'AL'},
        {nome: 'Amapá', sigla: 'AP'},
        {nome: 'Amazonas', sigla: 'AM'},
        {nome: 'Bahia', sigla: 'BA'},
        {nome: 'Ceará', sigla: 'CE'},
        {nome: 'Espírito Santo', sigla: 'ES'},
        {nome: 'Goiás', sigla: 'GO'},
        {nome: 'Maranhão', sigla: 'MA'},
        {nome: 'Mato Grosso', sigla: 'MT'},
        {nome: 'Mato Grosso do Sul', sigla: 'MS'},
        {nome: 'Minas Gerais', sigla: 'MG'},
        {nome: 'Pará', sigla: 'PA'},
        {nome: 'Paraíba', sigla: 'PB'},
        {nome: 'Paraná', sigla: 'PR'},
        {nome: 'Pernambuco', sigla: 'PE'},
        {nome: 'Piauí', sigla: 'PI'},
        {nome: 'Rio de Janeiro', sigla: 'RJ'},
        {nome: 'Rio Grande do Norte', sigla: 'RN'},
        {nome: 'Rio Grande do Sul', sigla: 'RS'},
        {nome: 'Rondônia', sigla: 'RO'},
        {nome: 'Roraima', sigla: 'RR'},
        {nome: 'Santa Catarina', sigla: 'SC'},
        {nome: 'São Paulo', sigla: 'SP'},
        {nome: 'Sergipe', sigla: 'SE'},
        {nome: 'Tocantins', sigla: 'TO'},
        {nome: 'Distrito Federal', sigla: 'DF'}
      ],
      bankCod: '',
      accountType: {tipo:'Conta corrente', val:'corrente'},
      accountTypeItems: [{tipo:'Conta corrente', val:'corrente'}, {tipo:'Conta poupança', val:'poupanca'}],
      bank: '',
      bankAgency: '',
      bankAccount: '',
      accountDigit: '',

      addressZipCode: '',
      addressStreet: '',
      addressNumber: '',
      addressComplement: '',
      addressNeighborhood: '',
      addressCity: '',
      addressState: '',
      apiCep: 'https://viacep.com.br/ws/',

      transferType: "1",
      pixKey: "",
      pixKeyCel: '',
      pixKeyCpf: '',
      pixKeyEmail: '',
      pixKeyRandon: '',
      pixType: {tipo:'celular', val:'celular'},
      pixTypeItems: [{tipo:'celular', val:'celular'}, {tipo:'CPF', val:'cpf'}, {tipo:'e-mail', val:'email'}, {tipo:'aleatória', val:'aleatoria'}],
    }),

    computed: {
      banks() {
        return this.$store.state.users.banks
      },
      eventId(){
        var idInput = /[^/]*$/.exec(window.location.pathname).input
        var urlArray = idInput.split("/")
        return urlArray[urlArray.length - 1]
      },
      eventData(){
        return this.$store.state.events.event
      },
      validCPF () { // validação de CPF
        const validCPF = []
        const isValidCPF =
          v => this.validateCPF(v.trim().replace(/[^0-9]/g, '')) || `CPF inválido`

        validCPF.push(isValidCPF)
        return validCPF
      }

    },

    methods: {
      ...mapActions(['getBanks', 'getEvent', 'updateUser']),
      async getEventData(){
        await this.getEvent({ eventId: this.eventId })

          var bankData = {}
          if(this.eventData.user.bankData.bankNumber != undefined){
            bankData = this.eventData.user.bankData
          }
          if(this.eventData.user.bankData.transfer){
            bankData = this.eventData.user.bankData.transfer
          }
          if(this.eventData.user.bankData.pix){
            bankData = this.eventData.user.bankData.pix
          }


          if(this.eventData.user.bankData){ // Nome e CPF
            this.name = this.eventData.user.bankData.name
            this.cpf = this.eventData.user.bankData.cpf
          }

          // JSON novo Pix
          if(this.eventData.user.bankData.pix){
            this.transferType = "2"
            this.pixType = {tipo:this.eventData.user.bankData.pix.keyType, val:this.eventData.user.bankData.pix.keyType}
            switch (this.eventData.user.bankData.pix.keyType) {
              case "celular":
                this.pixKeyCel = this.eventData.user.bankData.pix.key
                break;
              case "cpf":
                this.pixKeyCpf = this.eventData.user.bankData.pix.key
                break;
              case "email":
                this.pixKeyEmail = this.eventData.user.bankData.pix.key
                break;
              default:
                this.pixKeyRandon = this.eventData.user.bankData.pix.key
                break;
            }
          }

          if(this.eventData.user.bankData.pix == undefined){

            this.bankCod = bankData.bankNumber
            this.bankAgency = bankData.agency
            this.bankAccount = bankData.account.split('-')[0]

            if(bankData.account.split('-')[1] != undefined){
              this.accountDigit = bankData.account.split('-')[1]
            }

            switch (bankData.accountType) {
              case 'poupanca':
                this.accountType = {tipo:'Conta poupança', val:'poupanca'}
                break;
              default: this.accountType = {tipo:'Conta corrente', val:'corrente'}
            }
          }

          if(this.eventData.user.address){
            this.addressZipCode = this.eventData.user.address.zipCode
            this.addressStreet = this.eventData.user.address.streetName
            this.addressNumber = this.eventData.user.address.streetNumber
            this.addressComplement = this.eventData.user.address.additionalAddress
            this.addressNeighborhood = this.eventData.user.address.neighborhood
            this.addressCity = this.eventData.user.address.city
            this.addressState = this.eventData.user.address.state
          }
        //console.log(JSON.stringify(this.eventData,null,2))
      },
      async getBankList(){
        await this.getBanks()
        this.bankList = this.banks
        this.getEventData()
        // console.log(JSON.stringify(this.banks,null,2))
      },
      async save(){

        if(this.$refs.form.validate()){

          var payload = {
              _id: this.eventId,
              telefone: this.celular.trim().replace(/[^0-9]/g, ''),
              address: {
                zipCode: this.addressZipCode.trim().replace(/[^0-9]/g, ''),
                streetName: this.addressStreet,
                streetNumber: this.addressNumber,
                neighborhood: this.addressNeighborhood,
                city: this.addressCity,
                state: this.addressState
              }
          }
          if(this.addressComplement != ''){ payload.address.additionalAddress = this.addressComplement }

          payload.bankData = {
            name: this.name,
            cpf: this.cpf.trim().replace(/[^0-9]/g, '')
          }

          if(this.transferType == "2"){ // se for pix

            var type = ''
            if(this.pixType.val) { type = this.pixType.val}else{ type = this.pixType }

            switch (type) {
              case "celular":
                this.pixKey = this.pixKeyCel.trim().replace(/[^0-9]/g, '')
                break;
              case "cpf":
                this.pixKey = this.pixKeyCpf.trim().replace(/[^0-9]/g, '')
                break;
              case "email":
                this.pixKey = this.pixKeyEmail
                break;
              case "aleatoia":
                this.pixKey = this.pixKeyRandon
                break;
              default:
                break
            }

            payload.bankData.pix = {}
            payload.bankData.pix.keyType = type
            payload.bankData.pix.key = this.pixKey

          }else{
            var bankAccount = this.bankAccount
            if(this.accountDigit.length > 0){ bankAccount = bankAccount+'-'+this.accountDigit }

            if(this.accountType.val == 'corrente'){
              this.accountType = 'corrente'
            }
            payload.bankData.transfer = {}
            payload.bankData.transfer.account = bankAccount,
            payload.bankData.transfer.accountType = this.accountType,
            payload.bankData.transfer.agency = this.bankAgency,
            payload.bankData.transfer.bankNumber = this.bankCod,
            payload.bankData.transfer.bankName = this.bankList.find(x => x.cod === this.bankCod).banco.split(' - ')[1]
          }

          console.log(JSON.stringify(payload,null,2))
          await this.updateUser(payload)
        }
      },

      customFilter (item, queryText) {
        // const textOne = item.banco.toLowerCase()
        const textOne = item.banco.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        const textTwo = item.cod.toLowerCase()
        const searchText = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
      validateCPF(cpf){
          let sum
          let rest
          sum = 0
          if (cpf == "00000000000") return false
          for (let i=1; i<=9; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11))  { rest = 0 }
          if (rest != parseInt(cpf.substring(9, 10)) ) { return false }
          sum = 0;
          for (let i = 1; i <= 10; i++) { sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i) }
          rest = (sum * 10) % 11
          if ((rest == 10) || (rest == 11)) { rest = 0 }
          if (rest != parseInt(cpf.substring(10, 11) ) ) { return false }
          return true
      },
      // -------------------------- CEP ------------------------------
      fill(){
        this.addressStreet = '...'
        this.addressNeighborhood = '...'
        this.addressCity = '...'
        this.addressState = '...'

        this.addressZipCode = this.addressZipCode.trim().replace(/[^0-9]/g, '')
        var self = this

        var request = new XMLHttpRequest();
        request.open('get', "https://api.pagar.me/1/zipcodes/"+this.addressZipCode, true)
        // request.open('get', "https://viacep.com.br/ws/"+this.addressZipCode+"/json/", true)
        request.send()

        request.onload = function () {
          var data = JSON.parse(this.response)
          // console.log(data)
          if(!data.erro) {
            self.addressStreet = data.street
            self.addressNeighborhood = data.neighborhood
            self.addressCity = data.city
            self.addressState = data.state
            self.$refs.numero.focus()
          }else{
            console.log('CEP não encontrado')
            this.cleanForm()
          }
        }
      },
      cleanForm() {
        //Limpa valores do formulário de cep.
        this.addressStreet = ''
        this.addressNumber = ''
        this.addressComplement = ''
        this.addressNeighborhood = ''
        this.addressCity = ''
        this.addressState = ''
      }

    },
    mounted(){
      this.getBankList()
    }
  }
</script>
