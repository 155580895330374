<template lang='pug' src="./LoanList.pug">
</template>
<script>

  import { mapActions, mapGetters } from 'vuex'
  import { buildPayloadPagination } from '@/utils/utils.js'
  import filter from 'lodash/filter'

  export default {
    data: () =>({
      searchStatus: ['authorized'],
      detaildItem: {},
      detailsDialog: false,

      fab: false,
      hover: true,

      dialog: false,
      selectedId: '',
      selectedStatus: '',
      selected: [],
      dataTableLoading: false,
      expanded: [],
      singleExpand: false,
      search: '',
      pagination: {
        sortBy: ['createdAt'],
        sortDesc: ['true']
      },

      fieldsToSearch: ['key', 'status'], // definido no código ('bankData.name' ou 'status')

      loanId: null,
      itemCopied: false
    }),
    computed: {
      ...mapGetters(['loans', 'totalLoans', 'loanStatus', 'dash']),
      checkboxRule() {
        return [
          this.searchStatus.length > 0 || "Selecione pelo menos 1"
        ]
      },
      headers() {
        return [
          { text: 'Solicitado em', value: 'createdAt' },

          { text: 'Nome', value: 'name' },
          { text: 'Valor do empréstimo', value: 'amountToTransfer', sortable: false },
          { text: 'Status', value: 'status'},
          { text: 'Ações', value: 'actions' }
        ]
      }
    },
    methods: {
      ...mapActions(['getLoans', 'updateLoans', 'captureLoan', 'starkBankLoan']),

      informLoanPayment(costumer){
        console.log(JSON.stringify(costumer,null,2))
        let firstName = costumer.user.name.split(' ')[0]
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        const text = `?text=Olá%20${firstName}!%20Seu%20cr%C3%A9dito%20no%20valor%20de%20R$${costumer.amountToTransfer}%20foi%20transferido%20com%20sucesso%20em%20${new Date(costumer.updatedAt).toLocaleDateString('pt-br')}.%20O%20iFraldas%20agradece%20a%20confiança%20;)`

        let href = 'https://wa.me/55'+costumer.user.telefone.replace( /\D+/g, '')+text
        window.open(href, '_blank');
      },
      downloadNF(link){
        let href = link
        window.open(href, '_blank');
      },
      starkBankReceipt(link){
        let href = link
        window.open(href, '_blank');
      },

      async capture(id){
        await this.captureLoan({ _id: id })
        if(this.loanStatus == 'captured'){
          let array = filter( this.loans, {_id: id } )
          array.forEach(function(item){ item.status = 'captured' })
        }

      },

      async lendWithStarkBank(loanId){
        await this.starkBankLoan({ id: loanId })
      },

      fullImg(url) {
        window.open(url, '_blank');
      },

      showDocs(item) {

        console.log(JSON.stringify(item,null,2))

        this.detaildItem = item
        this.detailsDialog = true
      },

      getColor(item){

        if (item.status == 'authorized') return 'blue'
        if (item.status == 'new') return '#CCC'
        if (item.status == 'paid') return '#3cb371'
        if (item.status == 'captured') return '#FFBA49'
        if (item.status == 'waiting_payment') return '#FFBA49'
        if (item.status == 'refused') return '#EBBC12'
        if (item.status == 'fraud') return '#FD6A6E'
          else return '#CCC'
      },
      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      async doSearch() {
        try {
          this.dataTableLoading = true
          await this.getLoans(
            buildPayloadPagination(this.pagination, this.buildSearch())
          )
          this.dataTableLoading = false
          this.dash.notifications.bankRequests = 0

        } catch (error) {
          console.log('error', error)
          this.dataTableLoading = false
        }
      },
      buildSearch() {
        return this.search
          ? { status: this.searchStatus.join(','), searchTerm: this.search } // alterado para o novo módo de pesquisa do KK - Não reutilizável
          : { status: this.searchStatus.join(',') }
      },

      confirmChange(id, status){
        this.dialog = true
        this.selectedId = id
        this.selectedStatus = status
      },
      informFraud(id, status){
        this.dialog = true
        this.selectedId = id
        if(status != 'fraud'){
          this.selectedStatus = 'fraud'
        }else{
          this.selectedStatus = 'paid'
        }
      },
      async checkOneLoan(){
        this.dialog = false
        var newStatus = this.selectedStatus

        var payload = {}

        payload = { loans: [ { _id: this.selectedId, status: newStatus } ] }

        await this.updateLoans(payload)

        this.doSearch()
      },
      async checkLoans(){
        this.dialog = false

        var obj = this.selected
        var loans = []
        Object.keys(obj).forEach(function(k){
          loans[k] = { _id: obj[k]._id, status: 'paid'}
        })


        var payload = { loans: loans }
        console.log(JSON.stringify(payload,null,2))
        await this.updateLoans(payload)
        this.doSearch()

      },
    },
    mounted() {

    },
    watch: {
      searchStatus(){
        if(this.searchStatus.length>0){
          this.doSearch()
        }
      },
      pagination: {
      async handler() {
        // this.pagination.key = this.search
        this.doSearch()
      },
        deep: true
      }

    }
  }
</script>
<style>

    .differentCpf{ color: red !important; }
    /* CSS corretivo: no Safari Mobile a tabela desconfigurava após expandir algum item */
    tr.v-data-table__expanded__content{
      box-shadow: none !important;
      background-color: #F9F9F9;
    }

    table tbody .v-data-table__mobile-table-row{
      display: block !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content{
      display: block !important;
      width: 100% !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content td{
      display: table;
      width: 100% !important;
    }

    .hiddenDiv{
      display: none;
    }

</style>
