import axios from 'axios'

export default {
  getLoans(params){

    return axios.get(`/bank/admin/requests`, {
      params
    })
  },

  updateLoans(payload){
    return axios.patch('/bank/admin/request', payload)
  },

  captureLoan(payload){
    return axios.post(`bank/admin/request/capture`, payload)
  }

}
