import api from '@/services/api/starkBank'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  //withdraws: state => state.withdraws,
}

const actions = {

  starkBankPay({ commit }, payload) {
    // console.log(JSON.stringify(payload,null,2))
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .starkBankPay(payload)
        .then(response => {
          console.log('starkBank response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            console.log('sucesso')
            // commit(types.WITHDRAWS, response.data.docs)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          console.log('starkBank error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  starkBankLoan({ commit }, payload) {
    console.log(JSON.stringify(payload,null,2))
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .starkBankLoan(payload)
        .then(response => {
          console.log('starkBank response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            console.log('sucesso')
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          console.log('starkBank error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

}

const mutations = {
  // [types.WITHDRAWS](state, value) {
  //   state.withdraws = value
  // },
}

const state = {
  // withdraws: [],
}

export default {
  state,
  getters,
  actions,
  mutations
}
