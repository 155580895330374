<template lang="pug" src="./EventTransactions.pug">

</template>
<script>

  import { mapActions } from 'vuex'

  export default {

    data: () => ({
      dataTableLoading: false,
      expanded: [],
      singleExpand: false,
    }),

    computed: {
      eventId(){
        var idInput = /[^/]*$/.exec(window.location.pathname).input
        var urlArray = idInput.split("/")
        console.log('eventId: '+urlArray[urlArray.length - 1])
        return urlArray[urlArray.length - 1]
      },
      transactions(){
        console.log(JSON.stringify(this.$store.state.events.eventTransactions,null,2))
        return this.$store.state.events.eventTransactions
      },
      transactionsReport(){
        return this.$store.state.events.eventTransactionsReport
      },
      headers(){
        return [
          { text: 'Comprado em', value: 'createdAt' },
          { text: 'Nome', value: 'name' },
          { text: 'Bruto', value: 'total', sortable: false },
          { text: 'iFraldas', value: 'ifraldasCommissionValue', sortable: false },
          { text: 'Gateway', value: 'gatewayCommissionValue', sortable: false },
          { text: 'Líquido', value: 'redeemable', sortable: false },
          { text: 'Status', value: 'status'},
        ]

      }
    },

    methods: {
      ...mapActions(['getEventTransactions']),
      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      getColor(item){
        if (item.status == 'paid') return '#90D1CF'
        if (item.status == 'waiting_payment') return '#FFDDA4'
        if (item.status == 'fraud') return '#FD6A6E'
          else return '#CCC'
      },
      // getColor(item){
      //   if (item.releaseDate == undefined) return '#CCC'
      //   if (new Date(item.releaseDate) <= new Date()) return '#20A39E' // disponível para resgate
      //     else return '#FFBA49'
      // },
      async getTransactions(){
        await this.getEventTransactions({ eventId: this.eventId })
        //console.log(JSON.stringify(this.transactions,null,2))
      }
    },

    mounted(){
      this.getTransactions()
    }

  }
</script>
<style>
  /* CSS corretivo: no Safari Mobile a tabela desconfigurava após expandir algum item */
  tr.v-data-table__expanded__content{
    box-shadow: none !important;
    background-color: #F9F9F9;
  }

  table tbody .v-data-table__mobile-table-row{
    display: block !important;
  }
  div.correctExpandableMobile tr.v-data-table__expanded__content{
    display: block !important;
    width: 100% !important;
  }
  div.correctExpandableMobile tr.v-data-table__expanded__content td{
    display: table;
    width: 100% !important;
  }
</style>
