export default [
  {
    path: '/promoter/:clientId?',
    name: 'promoter',
    meta: {
      requiresAuth: true,
      title: 'Cliente'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/promoter/Promoter.vue')
  }
]
