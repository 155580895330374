import api from '@/services/api/stores'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  storeId: state => state.storeId,
  storeData: state => state.storeData,
  stores: state => state.stores
}

const actions = {

  registerStore( { commit }, payload ) {
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise( (resolve, reject) => {
      api
        .registerStore(payload)
        .then( response => {

          if( response.status === 200 ) {
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        })
        .catch(error => {
            console.log('error', error)
          handleError(error, commit, reject)
        })
    })
  },

  getStores({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)

    return new Promise((resolve, reject) => {
      api
        .getStores(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.STORES, response.data)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  getStore({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .getStore(payload)
        .then(response => {

          if (response.status === 200) {
            commit(types.STORE_DATA, response.data)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  updateStore({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .updateStore(payload)
        .then(response => {
          if(response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )

          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  deleteStore({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .deleteStore(payload)
        .then(response => {

          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.DELETED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          console.log('delete error: '+JSON.stringify(error,null,2))
          handleError(error, commit, reject)
        })
    })
  }

}

const mutations = {
    [types.STORES](state, value) {
        state.stores = value
    },
    [types.STORE_ID](state, value) {
        state.storeId = value
    },
    [types.STORE_DATA](state, value) {
        state.storeData = value
    }
}

const state = {
  storeId: null,
  storeData: null,
  stores: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
