import api from '@/services/api/files'

import * as types from '@/store/mutation-types'
import { handleError } from '@/utils/utils.js'

const getters = {
  bannerUploaded: state => state.bannerUploaded,
  uploadPercent: state => state.uploadPercent,
  files: state => state.files
}

const actions = {

  async getUploadUrl({ commit }, payload) {
    commit(types.SHOW_CENTER_LOADING, true)
    try {
      const response = await api.getXhrUrl(payload)
      return response.data.url
    } catch (error) {
      commit(types.SHOW_CENTER_LOADING, false)
      console.log('error', error)
      handleError(error, commit)
    }
  },
  async xhrDelete({ commit }, payload) {

    return new Promise((resolve, reject) => { 
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getXhrUrl(payload)
        .then(response => {
          if(response.status === 200){
            console.log('Arquivo excluído com sucesso')
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
      }).catch(error => {
        handleError(error, commit, reject)
      })
    })
  },

  async xhrMove({ commit }, payload) {
    commit(types.SHOW_CENTER_LOADING, true)
    try {
      const copyResponse = await api.xhrCopy({
        op: 'COPY',
        cdn: payload.cdn,
        bucket: payload.bucket,
        filename: payload.filename,
        keyTo: payload.keyTo
      })
  
      if (copyResponse.status === 200) {
        const deleteResponse = await api.getXhrUrl({
          op: 'DELETE',
          cdn: payload.cdn,
          bucket: payload.bucket,
          filename: payload.filename
        })
  
        if (deleteResponse.status === 200) { 
          commit(types.SHOW_CENTER_LOADING, false)
          return true }
      }
      
      throw new Error("Failed to move file")
    } catch (error) {
      handleError(error, commit, false)
    }
  },

  async newUploadFile({ commit }, params) {    
    
    return new Promise((resolve, reject) => {
      try {

        const xhr = new XMLHttpRequest()
        xhr.open(params.op, params.url)

        xhr.upload.onprogress = function(event) {            
            if (event.loaded) {                
                const percentage = (event.loaded / event.total) * 100
                commit(types.UPLOAD_PERCENT, percentage) 
            }
        }

        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 300) {
                commit(types.BANNER_UPLOADED, true)
                commit(types.SHOW_BTN_LOADING, false)
                commit(types.UPLOAD_PERCENT, 0)
                resolve(true)
            } else {
                console.log('Falha no upload')
                commit(types.BANNER_UPLOADED, false)
                commit(types.SHOW_BTN_LOADING, false)
                commit(types.UPLOAD_PERCENT, 0)
                handleError(xhr.statusText, commit, reject)
                reject(false)
            }
        }

        xhr.onerror = function() {
            console.error('Erro no upload:', xhr.statusText)
        }

        xhr.send(params.formData)
      } catch (error) {
        console.error('Erro no upload:', error)
      }
    })
  },

}

const mutations = {
  [types.BANNER_UPLOADED](state, value) {
    state.bannerUploaded = value
  },
  [types.UPLOAD_PERCENT](state, value) {
    state.uploadPercent = value
  },
  [types.FILES](state, value) {
    state.files = value
  }
}

const state = {
  bannerUploaded: false,
  uploadPercent: 0,
  files: ''
}

export default {
  state,
  getters,
  actions,
  mutations
}
