export default [
    {
      path: '/stores/List',
      name: 'stores-list',
      meta: {
        requiresAuth: true,
        title: 'Lojas'
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/components/stores/List.vue')
    },
    {
      path: '/stores/Edit/:storeId?',
      name: 'stores-edit',
      meta: {
        requiresAuth: true,
        title: 'Editar loja'
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/components/stores/Register.vue')
    },
    {
      path: '/stores/Register',
      name: 'stores-register',
      meta: {
        requiresAuth: true,
        title: 'Cadastrar loja'
      },
      component: () =>
        import(/* webpackChunkName: "profile" */ '@/components/stores/Register.vue'),
      props: true  
    }
  
  ]
  