import axios from 'axios'

export default {
  getWithdraws(params){

    return axios.get(`/withdraws/admin/list`, {
      params
    })
  },

  getListTransactions(params){
    return axios.get(`/withdraws/admin/list-transactions/${params.withdrawId}`)
  },

  updateWithdraws(payload){
    return axios.patch('/withdraws/admin/update', payload)
  }

}
