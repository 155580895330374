var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v("Compras detalhadas")]),_c('v-card-text',{staticClass:"mt-4"},[_c('div',{class:{'correctExpandableMobile': _vm.$vuetify.breakpoint.smAndDown}},[_c('v-data-table',{staticClass:"mt-4 elevation-0",attrs:{"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"_id","show-expand":"","loading":_vm.dataTableLoading,"headers":_vm.headers,"header-props":{ 'sortByText':'Ordenar por' },"items":_vm.purchases,"sort-by":['createdAt', 'name'],"sort-desc":[true, false],"multi-sort":"","no-data-text":"Nenhuma transação encontrada","footer-props":{ 'items-per-page-options': [20, 50, 100], 'items-per-page-text':'itens' },"loading-text":"carregando"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_c('span',{staticClass:"font-weight-bold"},[_vm._v("PLANO PREMIUM")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-chip',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.total)))])],1)]}},{key:"item.ifraldasCommissionValue",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-chip',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.ifraldasCommissionValue)))])],1)]}},{key:"item.gatewayCommissionValue",fn:function(ref){
var item = ref.item;
return [(item.gatewayCommissionValue)?_c('span',[_c('v-chip',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.gatewayCommissionValue)))])],1):_c('span',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.gatewayCommissionValue))+" ")])]}},{key:"item.redeemable",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-chip',{attrs:{"dark":""}},[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.redeemable)))])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-chip',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleDateString('pt-br')))])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.name)?_c('td',{attrs:{"colspan":headers.length}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center mb-2 mt-2"},[(item.releaseDate)?_c('span',{staticClass:"caption"},[_vm._v("Data da liberação: "),_c('strong',[_vm._v(_vm._s(new Date(item.releaseDate).toLocaleDateString('pt-br'))+" às "+_vm._s(new Date(item.releaseDate).toLocaleTimeString('pt-br')))])]):_c('span',{staticClass:"caption"},[_vm._v("Data da liberação: "),_c('strong',[_vm._v("Aguardando pagamento")])])]),_vm._l((item.items),function(item,i){return _c('div',{staticClass:"text-center mb-2 mt-2"},[_c('strong',[_vm._v(_vm._s(item.quantity)+"x")]),_vm._v(" "+_vm._s(item.title)+" no valor de: "),_c('strong',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.unit_price/100)))])])}),_c('div',{staticClass:"text-center mb-2 mt-2"},[_vm._v("Total: "),_c('strong',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.total)))])]),_c('div',{staticClass:"text-center mb-2 mt-2"},[(item.paymentMethod == 'credit_card')?_c('span',{staticClass:"caption grey--text text--darken-1"},[_vm._v("Pago com "),_c('strong',[_vm._v("Cartão de Crédito")]),_vm._v(" | ")]):_vm._e(),(item.paymentMethod == 'boleto')?_c('span',{staticClass:"caption grey--text text--darken-1"},[_vm._v("Pago com "),_c('strong',[_vm._v("Boleto")]),_vm._v(" | ")]):_vm._e(),(item.paymentMethod == 'pix')?_c('span',{staticClass:"caption grey--text text--darken-1"},[_vm._v("Pago com "),_c('strong',[_vm._v("Pix")]),_vm._v(" | ")]):_vm._e()])],2)],1):_vm._e()]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }