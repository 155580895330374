import axios from 'axios'

export default {
  getGifts(params){
    if(params && params.query){
      return axios.get(`/gifts/admin/list${params.query}`, { params })
    }else {
      return axios.get(`/gifts/admin/list`, { params })
    }
  },
  getGift(payload){
    return axios.get(`/gifts/admin/list?_id=${payload.id}`)
  },
  registerGift(payload){
    return axios.post(`/gifts/admin`, payload)
  },
  updateGift(payload){
    return axios.patch(`/gifts/admin`, payload)
  },
  deleteGift(payload){
    return axios.delete(`/gifts/admin/`, {data: payload})
  }
}
