import api from '@/services/api/users'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  userId: state => state.userId,
  userData: state => state.userData,
  banks: state => state.banks
}

const actions = {

  registerUser( { commit }, payload ) {
    return new Promise( (resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .registerUser(payload)
        .then( response => {
          if( response.status === 200 ) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              () => {
                resolve(true)
              }
            )
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  deleteUser({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .deleteUser(payload)
        .then(response => {

          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.DELETED_SUCCESSFULLY' },
              commit,
              () => {
                resolve(true)
              }
            )
          }
        }).catch(error => {
          console.log('delete error: '+JSON.stringify(error,null,2))
          handleError(error, commit, reject)
        })
    })
  },

  getUser({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .getUser(payload)
        .then(response => {
          //console.log(JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.USER_DATA, response.data)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        })
        .catch(error => {
          handleError(error, commit, reject)
        })
    })
  },

  updateUser({ commit }, payload){
    commit(types.SHOW_CENTER_LOADING, true)
    return new Promise((resolve, reject) => {
      api
        .updateUser(payload)
        .then(response => {
          if(response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              () => {
                resolve(true)
              }
            )

          }
        })
        .catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  }

}

const mutations = {
  [types.USER_ID](state, value) {
    state.userId = value
  },
  [types.USER_DATA](state, value) {
    state.userData = value
  },
  [types.BANKS](state, value) {
    state.banks = value
  },
}

const state = {
  userId: null,
  emailExists: false,
  userData: null,
  banks: null
}

export default {
  state,
  getters,
  actions,
  mutations
}
