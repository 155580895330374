import axios from 'axios'
// import qs from 'qs'

export default {
  getStore(params){
    return axios.get(`/stores/admin/${params._id}`)
  },

  getStores(params){
    return axios.get(`/stores/admin`, { params } )    
  },

  registerStore(payload){
    return axios.post(`/stores/admin`, payload)
  },
  updateStore(payload){
    return axios.patch(`/stores/admin`, payload)
  },
  deleteStore(payload){
    return axios.delete(`/stores/admin`, {data: payload})
  }
}