export default [
  {
    path: '/partners/List',
    name: 'partners-list',
    meta: {
      requiresAuth: true,
      title: 'Parceiros'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/partners/List.vue')
  },
  {
    path: '/partners/Edit/:partnerId?',
    name: 'partner-edit',
    meta: {
      requiresAuth: true,
      title: 'Editar parceiro'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/partners/Edit.vue')
  },
  {
    path: '/partners/Codes/:partnerId?',
    name: 'partner-codes',
    meta: {
      requiresAuth: true,
      title: 'Gerenciar códigos'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/partners/Codes.vue'),
    props: true  
  }

]
