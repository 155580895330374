<template lang="pug" src="./Event.pug">

</template>
<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import {mask} from 'vue-the-mask'
import axios from 'axios'
import moment from 'moment-timezone'

import {VMoney} from 'v-money'

  export default{

    directives: {mask, money: VMoney},

    data () {
      return {
        rules: {
          required: value => !!value || 'Campo obrigatório'
        },

        modalDateDemoExpires: false,
        modalDateExpires: false,
        demoExpires: new Date().toISOString().substr(0, 10),
        expires: new Date().toISOString().substr(0, 10),
        demoExpiresFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
        expiresFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
        minDate: new Date().toISOString().slice(0,10),
        today: new Date(),

        bucket: process.env.VUE_APP_S3_BUCKET,

        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        publicPath: process.env.BASE_URL,

        valid: true,
        value: true,

        plan: {name: 'Premium', val: '2'},
        plans: [{name: 'Básico', val: '1'}, {name: 'Premium', val: '2'}, {name: 'Degustação', val: '3'}, {name: 'Premium gratuito', val: '4'}],
        selectedPlan: '',
        planIcon: 'mdi-star',
        photoAlbum: [],
        banner: '',
        boletoFee: 0,
        cardCommission: 0,
        ifraldasCommission: 0,
        pixCommission: 0,
        gatewayCommission: undefined,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        advanceable: false,
        suspect: false,
        charitable: false
      }
    },

    computed: {
      ...mapGetters(['showBtnLoading']),

      eventId(){
        var idInput = /[^/]*$/.exec(window.location.pathname).input
        var urlArray = idInput.split("/")
        return urlArray[urlArray.length - 1]
      },
      eventData(){
        return this.$store.state.events.event
      },
    },

    methods: {
      ...mapActions(['getEvent', 'updateEvent', 'loadS3']),

      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

      async saveDate(type){

        let ajustedDate = new Date()
        const hours = new Date().getTimezoneOffset()

        switch (type) {
          case 'demoExpires':

            ajustedDate = new Date(this.demoExpires)
            ajustedDate.setHours(ajustedDate.getHours() + (hours/60))
            await this.updateEvent({ _id: this.eventId, demoExpires: new Date(ajustedDate).toISOString() })
            break
          default:
            ajustedDate = new Date(this.expires)
            ajustedDate.setHours(ajustedDate.getHours() + (hours/60))
            await this.updateEvent({ _id: this.eventId, expires: new Date(ajustedDate).toISOString() })
            break
        }

        // this.dateFormatted = this.formatDate(new Date(this.event.doc.date).toISOString().substr(0, 10))
      },

      onButtonClick() {
        this.isSelecting = true
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.picture.click()
      },

      async forceFileDownload(response) {

        //let objectURL = 'data:image/jpeg;base64,' + response
        // string to Blob
        const byteCharacters = atob(response)
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {type: 'image/jpeg'})

        const data = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = data
        link.setAttribute('download', 'iFraldas.jpg')
        document.body.appendChild(link)
        link.click()
      },

      openPhotoInNewTab(url){
        window.open(this.imgPath+'/'+url, '_blank').focus()
      },

      async downloadImage(url) {
        const uri = this.imgPath+'/'+url

        var base64 = await axios
        .get(uri, {
          crossDomain: true,
          responseType: "arraybuffer",
          headers: {"Access-Control-Request-Method": "GET"}
        })
        .then(response =>
          Buffer.from(response.data, "binary").toString("base64")
        )
        .catch(() => console.log('erro no download'))

        this.forceFileDownload(base64)
      },

      setPlan(){
        this.selectedPlan = this.plan
      },

      async getEventData(){

        await this.getEvent({ eventId: this.eventId })

        if(this.eventData.demoExpires != undefined){
          this.demoExpires = moment(this.eventData.demoExpires).format('YYYY-MM-DD')
        }else { this.demoExpires = '' }

        this.expires = moment(this.eventData.expires).format('YYYY-MM-DD')

        this.selectedPlan = this.eventData.plan

        //  {name: 'Básico', val: '1'}, {name: 'Premium', val: '2'}, {name: 'Degustação', val: '3'}, {name: 'Premium gratuito', val: '4'}
        switch (this.eventData.plan) {
          case 2:
              this.plan = {name: 'Premium', val: '2'}
              this.planIcon = 'mdi-star'
            break;
          case 3:
              this.plan = {name: 'Degustação', val: '3'}
              this.planIcon = 'mdi-star-half'
            break;
          case 4:
              this.plan = {name: 'Premium gratuito', val: '4'}
              this.planIcon = 'mdi-star-circle'
            break;
          default:
              this.plan = {name: 'Básico', val: '1'}
              this.planIcon = 'mdi-star-outline'
        }

        this.photoAlbum = this.eventData.photoAlbum
        this.banner = this.eventData.banner

        if(this.eventData.advanceable != undefined) { this.advanceable = this.eventData.advanceable }
        if(this.eventData.charitable != undefined) { this.charitable = this.eventData.charitable }
        if(this.eventData.suspect != undefined) { this.suspect = this.eventData.suspect }

        if(this.eventData.boletoFee && document.querySelector("input[name=boletoFee]")){ document.querySelector("input[name=boletoFee]").value = parseInt((this.eventData.boletoFee*100).toFixed()) }

        this.ifraldasCommission = parseFloat(this.eventData.ifraldasCommission * 100).toFixed(2)

        if(this.eventData.cardCommission){ this.cardCommission = parseFloat(this.eventData.cardCommission * 100).toFixed(2) }
        if(this.eventData.pixCommission){ this.pixCommission = parseFloat(this.eventData.pixCommission * 100).toFixed(2) }
        if(this.eventData.gatewayCommission){ this.gatewayCommission = parseFloat(this.eventData.gatewayCommission * 100).toFixed(2) }
      },
      async save(){

        if(this.$refs.form.validate()){

          var payload = {
            _id: this.eventId,
            plan: Number(this.selectedPlan),
            role: 'promoter',
            ifraldasCommission: parseFloat(this.ifraldasCommission/100).toFixed(4),
            advanceable: this.advanceable,
            charitable: this.charitable
          }

          if(this.gatewayCommission != null){
            payload.gatewayCommission = parseFloat(this.gatewayCommission/100).toFixed(4)
          }else{
            payload.boletoFee = parseFloat(this.boletoFee.replace(/\D/g,'')/100).toFixed(2),
            payload.cardCommission = parseFloat(this.cardCommission/100).toFixed(4),
            payload.pixCommission = parseFloat(this.pixCommission/100).toFixed(4)
          }

          await this.updateEvent(payload)
        }
      },

    },
    mounted(){
      this.getEventData()
    },
    watch: {
      demoExpires () {
        this.demoExpiresFormatted = this.formatDate(this.demoExpires)
      },
      expires () {
        this.expiresFormatted = this.formatDate(this.expires)
      },
    }

  }
</script>
<style>
  .btn-toggle {
    flex-direction: column;
  }
  .centerBox{
    width: 136px;
    margin: auto;
  }
</style>
