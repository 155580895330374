import api from '@/services/api/withdraws'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  withdraws: state => state.withdraws,
  totalWithdraws: state => state.totalWithdraws,
  withdrawsReport: state => state.withdrawsReport,
  purchases: state => state.purchases
}

const actions = {

  getWithdraws({ commit }, payload) {
    // console.log(JSON.stringify(payload,null,2))
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getWithdraws(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.WITHDRAWS, response.data.docs)
            commit(types.TOTAL_WITHDRAWS, response.data.totalDocs)
            commit(types.WITHDRAWS_REPORT, response.data.report)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getListTransactions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getListTransactions(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.PURCHASES, response.data.docs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  updateWithdraws({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .updateWithdraws(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

}

const mutations = {
  [types.WITHDRAWS](state, value) {
    state.withdraws = value
  },
  [types.TOTAL_WITHDRAWS](state, value) {
    state.totalWithdraws = value
  },
  [types.WITHDRAWS_REPORT](state, value) {
    state.withdrawsReport = value
  },
  [types.PURCHASES](state, value) {
    state.purchases = value
  },
}

const state = {
  withdraws: [],
  totalWithdraws: 0,
  withdrawsReport: {},
  purchases: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
