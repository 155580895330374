import api from '@/services/api/loans'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  loans: state => state.loans,
  totalLoans: state => state.totalLoans,
  loanStatus: state => state.loanStatus
}

const actions = {

  getLoans({ commit }, payload) {
    //console.log(JSON.stringify(payload,null,2))
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getLoans(payload)
        .then(response => {
          //console.log('getLoans response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.LOANS, response.data.docs)
            commit(types.TOTAL_LOANS, response.data.totalDocs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  updateLoans({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .updateLoans(payload)
        .then(response => {
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  captureLoan({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .captureLoan(payload)
        .then(response => {
          console.log(JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            console.log('salvando:',response.data.status)
            commit(types.LOAN_STATUS, response.data.status)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          console.log(JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

}

const mutations = {
  [types.LOANS](state, value) {
    state.loans = value
  },
  [types.TOTAL_LOANS](state, value) {
    state.totalLoans = value
  },
  [types.LOAN_STATUS](state, value) {
    state.loanStatus = value
  }
}

const state = {
  loans: [],
  totalLoans: 0,
  loanStatus: null
}

export default {
  state,
  getters,
  actions,
  mutations
}
