<template lang="pug" src="./Transactions.pug">

</template>
<script>
  import { mapActions } from 'vuex'
  import moment from 'moment-timezone'
  import filter from 'lodash/filter'

  export default {
    name: 'Transactions',
    data: () => ({
      dataTableLoading: false,
      expanded: [],
      singleExpand: false,
      itemsPerPage: 10,
      options: {},

      radioGroup: '1',
      rangeOptions: [{label: "Hoje", value: '1'}, {label: "Ontem", value: '2'}, {label: "Últimos 7 dias", value: '3'}, {label: "Últimos 30 dias", value: '4'}],

      searchStatus: ['paid'],
      statusOptions: [{label: "Pagas", value: 'paid'}, {label: "Aguardando pagamento", value: 'waiting_payment'}, {label: "Canceladas pelo cliente", value: 'chargedback'}, {label: "Recusadas", value: 'refused'}, {label: "Estornadas pelo iFraldas", value: 'refounded'}],

      searchMethod: ['credit_card','pix','boleto'],
      methodOptions: [{label: "Cartão", value: 'credit_card'}, {label: "Pix", value: 'pix'}, {label: "Boleto", value: 'boleto'}],

      startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      datesRange: [(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10), (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
      key: '',
      eventId: '',
      minVal: 0,
      maxVal: '',
      suspectDialog: false,
      unsuspectDialog: false,
      editingItem: {},

      removeDuplicate: false
    }),
    methods: {
      ...mapActions(['getTransactions', 'adminLogin', 'updateEvent']),

      loginAsPromoter(eventId){
        this.adminLogin({ eventId: eventId })
      },

      openSuspectConfirmation(item){
        this.editingItem = item
        this.suspectDialog = true
      },

      openUnsuspectConfirmation(item){
        this.editingItem = item
        this.unsuspectDialog = true
      },

      async markAsSuspect(){
        await this.updateEvent({ _id: this.editingItem.event._id, suspect: true, advanceable: false })

        let array = filter( this.transactions, { event : {_id: this.editingItem.event._id }} )
        array.forEach(function(item){ item.event.suspect = true })

        this.suspectDialog = false
      },

      async markAsUnsuspect(){
        await this.updateEvent({ _id: this.editingItem.event._id, suspect: false })

        let array = filter( this.transactions, { event : {_id: this.editingItem.event._id }} )
        array.forEach(function(item){ item.event.suspect = false  })

        this.unsuspectDialog = false
      },

      openWhatsApp(item){

        let firstName = item.name.split(' ')[0]
        firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1)
        
        let explanatoryText = ''
        let paymentCode = ''

        switch(item.paymentMethod) {
          case 'boleto': 
            explanatoryText = 'Para facilitar, segue abaixo link para pagamento do boleto.'
            paymentCode = item.boletoUrl
            break
          case 'pix': 
            explanatoryText = 'Para facilitar, segue abaixo o código para pagamento.'
            paymentCode = item.pixQrCode
            break
          default: break
        }

        var text = 'Olá, '+firstName+'. Boa tarde!\n\n'+
          'Vi que vc comprou um presente por '+item.paymentMethod+' para o chá de bebê.\n\n'+
          explanatoryText+
          '\n\nEle também foi enviado para o seu e-mail.\n\n'+
          paymentCode

        var encodedText = encodeURI(text)
        console.log(encodedText)

        let href = 'https://wa.me/'+item.telefone.replace( /\D+/g, '')+'?text='+encodedText
        window.open(href, '_blank');
      },

      visitSite(slug){
        let href = 'https://cha.ifraldas.com.br/'+slug
        window.open(href, '_blank');
      },

      moment: function () {
          return moment()
      },
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      setRange(radioVal) {

        let endDate = new Date()

        switch(radioVal) {
          case '1':
            this.startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            break
          case '2':
            this.startDate = new Date().setDate(new Date().getDate() - 1)
            endDate = new Date().setDate(new Date().getDate() - 1)
            break
          case '3':
            this.startDate = new Date().setDate(new Date().getDate() - 6)
            break
          case '4':
            this.startDate = new Date().setDate(new Date().getDate() - 29)
            break
          default: break
        }
        this.datesRange = [moment(this.startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')]
      },

      async doSearch() {
        console.log('doSearch')
        this.dataTableLoading = true

        try {
          this.dataTableLoading = true
          await this.getTransactions( this.buildSearch() )
          this.dataTableLoading = false

        } catch (error) {
          this.dataTableLoading = false
        }

      },
      buildSearch() {

        let sortRules = [{}]
        for (var i = 0; i < this.options.sortBy.length; i++) {
          sortRules[i] = `${this.options.sortBy[i]},${this.options.sortDesc[i] ? -1 : 1}`
        }

        let payload = {}
        payload = {
          // sort: this.options.sortBy,
          // order: this.options.sortDesc,
          sort: sortRules,
          page: this.options.page,
          limit: this.options.itemsPerPage
        }

        const finalDate = new Date(`${this.datesRange[1]}T03:00:00.000Z`)
        let final = moment(finalDate).add(86399, 'seconds')
        final = moment(final).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z"

        payload.dateRange = `${this.datesRange[0]}T03:00:00.000Z,${final}`

        let valRange = []
        let min = 0
        let max = 99999
        if(this.minVal != ''){ min = this.minVal }
        if(this.maxVal != ''){ max = this.maxVal }
        valRange.push(min, max)
        payload.valueRange = valRange.join(',')

        if(this.searchStatus.length > 0){ payload.status = this.searchStatus.join(',') }
        if(this.searchMethod.length > 0){ payload.paymentMethod = this.searchMethod.join(',') }

        if(this.eventId != ''){ payload.event = this.eventId }

        if(this.removeDuplicate){ payload.removeDuplicate = true }

        if(this.key != ''){ payload.key = this.key }

        return payload
      }
    },
    computed: {
      transactions(){
        return this.$store.state.transactions.transactions
      },
      totalTransactions(){
        return this.$store.state.transactions.totalTransactions
      },
      headers() {
        return [
          { text: 'Compra', value: 'createdAt', sortable: false },
          { text: 'Nome', value: 'name', sortable: false },
          { text: 'Meio de pagamento', value: 'paymentMethod', sortable: false },
          { text: 'Valor líquido', value: 'total'},
          { text: 'Status', value: 'status', sortable: false},
          { text: 'Ações', value: 'actions', sortable: false}
        ]
      },
      dateRangeText () {
        let array = this.datesRange
        const sortedDatesRange = array.sort((a, b) => new Date(a) - new Date(b))
        let newArray = []
        sortedDatesRange.forEach((item, i) => {
          newArray[i] = moment(item).format('DD/MM/YYYY')
        })
        return newArray.join(' até ')
      },
    },
    mounted () { },
    watch: {
      options: {
        handler () {
          this.doSearch()
        },
        deep: true,
      }
    }
  }
</script>
<style>

    /* CSS corretivo: no Safari Mobile a tabela desconfigurava após expandir algum item */
    tr.v-data-table__expanded__content{
      box-shadow: none !important;
      background-color: #F9F9F9;
    }

    table tbody .v-data-table__mobile-table-row{
      display: block !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content{
      display: block !important;
      width: 100% !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content td{
      display: table;
      width: 100% !important;
    }

</style>
