import axios from 'axios'

export default {

  getTransactions(params) {
    // return axios.get(`/reports/admin/transactions/`, { params })
    return axios.get(`/transactions/admin/list`, { params })
  }
  
}
