<template lang="pug" src="./List.pug">

</template>
<script>

import { mapActions } from 'vuex'

  export default{
    data: () => ({
      search: '',
      bucket: process.env.VUE_APP_S3_BUCKET,
      publicPath: process.env.BASE_URL,

      delConfirm: false,
      id: '',
      name: '',
      pagination: {
        sortBy: ['name'],
        sortDesc: ['true']
      },
      itemsPerPage: 100,
      options: {}
      // parceiros: [ { _id: "1", name: "Rogério Maciel", email: "rogerioufjf@gmail.com", phone: "32988113203" } ]
    }),

    computed: {

      headers(){
        return [
          { text: 'Nome', value: 'name' },
          { text: 'E-mail', value: 'email' },
          { text: 'Telefone', value: 'telefone' },
          { text: 'Ações', value: 'actions' }
        ]
      },
      partners(){
        return this.$store.state.partners.partners
      },
      total(){
        return this.$store.state.partners.totalPartners
      }
    },

    methods: {
      ...mapActions(['getPartners', 'updatePartner']),
      deleteConfirm(item){
        this.name = item.name
        this.id = item.id
        this.delConfirm = true
      },
      async del(id){
        this.delConfirm = false
        await this.updatePartner({
          _id: id,
          role: 'promoter'
        })
        this.doSearch()
      },
      async listPartners(){
        await this.doSearch()
      },

      async doSearch() {

        this.pagination.page = this.options.page
        this.pagination.itemsPerPage = this.options.itemsPerPage

        try {
          this.dataTableLoading = true

          await this.getPartners( this.buildSearch() )

          this.dataTableLoading = false

        } catch (error) {
          this.dataTableLoading = false
        }

      },

      buildSearch() {

        let sortRules = [{}]
        for (var i = 0; i < this.options.sortBy.length; i++) {
          sortRules[i] = `${this.options.sortBy[i]},${this.options.sortDesc[i] ? -1 : 1}`
        }

        let payload = {}
        payload = {
          sort: sortRules,
          page: this.options.page,
          limit: this.options.itemsPerPage
        }
        if(this.search.length>0) { payload.key = this.search }
        return payload
      }
    },
    mounted(){
      this.doSearch()
    },
    watch: {

      options: {
        handler () {
          this.doSearch()
        },
        deep: true,
      }

    }
  }
</script>
<style scoped>
  .influencer-emphasis {
    color: blue;
    font-weight: bold;
  }
</style>