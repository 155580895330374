<template lang="pug" src="./Dashboard.pug">
</template>
<script>

  import { mapActions } from 'vuex'
  import { mapGetters } from 'vuex'
  import moment from 'moment-timezone'

  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3' // para esconder o recaptcha do Login
  Vue.use(VueReCaptcha, { siteKey: '6LfaK4IaAAAAAFyL_mC2cTeLgkcdpCOUVJn8QkOr' })

  export default{
    name: 'Painel',
    data: () => ({
    }),
    computed: {
      ...mapGetters(['user']),
      dash() {
        return this.$store.state.dash.dash
      }
    },
    methods: {
      ...mapActions(['getDash']),
      moment: function () {
          return moment()
      },
      async getDashData(){
        await this.getDash()
      },

      reloadDash(){
        this.getDashData()
      },

      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },

    },
    mounted(){

      // setTimeout(() => {
      //   const recaptcha = this.$recaptchaInstance
      //   recaptcha.hideBadge()
      // }, 1500);

      this.getDashData()
    }
  }
</script>
<style>
  .reloadBtnAbsolute{
    position: fixed;
    bottom: 20px;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
</style>
