export default [
  {
    path: '/gifts/List',
    name: 'gifts-list',
    meta: {
      requiresAuth: true,
      title: 'Lista de Presentes'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/gifts/List.vue')
  },
  {
    path: '/gifts/Edit/:giftId?',
    name: 'gifts-edit',
    meta: {
      requiresAuth: true,
      title: 'Presente'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/gifts/Edit.vue')
  }
]
