import api from '@/services/api/events'
import * as types from '@/store/mutation-types'
import { handleError, buildSuccess } from '@/utils/utils.js'

const getters = {
  events: state => state.events,
  totalEvents: state => state.totalEvents,
  event: state => state.event,
  eventTransactions: state => state.eventTransactions,
  eventTransactionsReport: state => state.eventTransactionsReport
}

const actions = {
  getEvents({ commit }, payload) {

    // console.log('getEvents payload: '+JSON.stringify(payload,null,2))

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getEvents(payload)
        .then(response => {
          if (response.status === 200) {

            // console.log('getEvents response: '+JSON.stringify(response.data,null,2))
            commit(types.EVENTS, response.data.docs)
            commit(types.TOTAL_EVENTS, response.data.totalDocs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          //console.log('getEvents error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getEvent({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getEvent(payload)
        .then(response => {

          //console.log('getEvent response: '+JSON.stringify(response,null,2))

          if (response.status === 200) {
            commit(types.EVENT, response.data.doc)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          //console.log('getEvent error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  getEventTransactions({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getEventTransactions(payload)
        .then(response => {
          console.log('getEvent response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.EVENT_TRANSACTIONS, response.data.transactions.docs)
            commit(types.EVENT_TRANSACTIONS_REPORT, response.data.report)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          //console.log('getEvent error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

  updateEvent({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .updateEvent(payload)
        .then(response => {
          //console.log('updateEvent response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.SHOW_CENTER_LOADING, false)
            buildSuccess(
              { msg: 'common.SAVED_SUCCESSFULLY' },
              commit,
              resolve
            )
          }
        }).catch(error => {
          //console.log('getEvent error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

}

const mutations = {
  [types.EVENTS](state, value) {
    state.events = value
  },
  [types.TOTAL_EVENTS](state, value) {
    state.totalEvents = value
  },
  [types.EVENT](state, value) {
    state.event = value
  },
  [types.EVENT_TRANSACTIONS](state, value) {
    state.eventTransactions = value
  },
  [types.EVENT_TRANSACTIONS_REPORT](state, value) {
    state.eventTransactionsReport = value
  }
}

const state = {
  events: [],
  totalEvents: 0,
  event: [],
  eventTransactions: [],
  eventTransactionsReport: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
