<template lang="pug" src="./Promoter.pug">

</template>
<script>

import {mask} from 'vue-the-mask'
import { mapActions } from 'vuex'

  export default{

    directives: {mask},

    data: () => ({
      valid: true,
      value: true,
      rules: {
        required: value => !!value || 'Campo obrigatório',
        maxLength: v => v.length <= 40 || 'Maximo de 40 caracteres',
        twoWords: value => (!!value && (/\w+\s+\w/).test(value)) || 'Digite o nome completo',
        //twoWords: value => (!!value && (/^((\b[A-zÀ-ú']{2,40}\b)\s*){2,}$/).test(value)) || 'Digite o nome completo'
      },
      emailRules: [
        v => !!v || 'Campo obrigatório',
        //v => !v || /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(v) || 'Digite um e-mail válido'
        v => /.+@.+\..+/.test(v) || 'Digite um e-mail válido',
      ],
      name: '',
      email: '',
      phone: '',
      howMetUs: 'Facebook',
      bank: '',
      accountType: '',
      bankAgency: '',
      bankAccount: '',
      eventActive: '',
      eventKind: '',
      slug: '',
      eventType: '',
      babyName: '',
      weeks: ''
    }),

    computed: {
      userId(){
        var idInput = /[^/]*$/.exec(window.location.pathname).input
        var urlArray = idInput.split("/")
        return urlArray[urlArray.length - 1]
      },
      userData(){
        return this.$store.state.users.userData
      }
    },

    methods: {
      ...mapActions(['getUser', 'updateUser']),

      openWhatsApp(phone){
        console.log(phone.replace( /\D+/g, ''))
        let href = 'https://wa.me/55'+phone.replace( /\D+/g, '')
        window.open(href, '_blank');
      },

      async getUserData(){
        await this.getUser({ userId: this.userId })
        this.name = this.userData.name
        this.email = this.userData.email
        this.phone = this.userData.telefone
      },
      async save(){

        if(this.$refs.form.validate()){
          var payload = {
            _id: this.userId,
            email: this.email,
            name: this.name,
            telefone: this.phone.trim().replace(/[^0-9]/g, ''),
            role: 'promoter'
          }
          //console.log(JSON.stringify(payload,null,2))
          await this.updateUser(payload)
        }

      },
      promoterEvents(){
        // this.$router.push({ path: `/events/${item.user}` })
      }
    },

    mounted() {
      this.getUserData()
    }

  }
</script>
