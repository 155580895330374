export default [
  {
    path: '/',
    name: 'permissionDenied',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "landing" */ '@/components/PermissionDenied.vue')
  }
]
