<template lang="pug" src="./Edit.pug">

</template>
<script>

import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'

import { dataURItoBlob } from "../../utils/images.js"
import { mapActions } from 'vuex'

import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

  export default{

    directives: {mask, money: VMoney},

    components: { Cropper },

    data: () => ({
      containerKey: 0,
      bucket: process.env.VUE_APP_S3_BUCKET,
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      publicPath: process.env.BASE_URL,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false /* doesn't work with directive */
      },

      title: '',
      price: '',
      raffles: '',
      giftImage: '',

      plan: {name: 'Básico', val: "1"},
      plans: [{name: 'Básico', val: "1"}, {name: 'Premium', val: "2"}],
      category: "Fraldas e higiene",
      categorys: ["Fraldas e higiene", "Especial Dia da Mulher", "Especial Mês das Mães", "Hora da papinha", "Para os passeios", "Hora do banho", "Para brincar", "Hora do soninho", "Para vestir"],
      planIcon: 'mdi-star',
      unlimited: false,
      active: true,
      editable: true,
      limit: 1,
      showClipper: false,
      rotation: 0,
      isSelecting: false,
      cropImageURL: '',

      rules: {
        required: value => !!value || 'Campo obrigatório',
        titleMaxLength: value => value.length <= 50 || 'Maximo de 50 caracteres'
      }

    }),
    computed: {
      gift(){
        return this.$store.state.gifts.gift
      },
      gId(){
        return this.$store.state.gifts.gId
      },
      uploadPercent(){
        return this.$store.state.files.uploadPercent
      },
      bannerUploaded(){
        return this.$store.state.files.bannerUploaded
      },
    },
    methods: {
      ...mapActions(['registerGift', 'updateGift', 'getUploadUrl', 'newUploadFile', 'getGift']),

      setRaffle(){
        let price = this.price.trim().replace(/[^0-9]/g, '')/100
        console.log(price)
        this.raffles = Math.round(price/20)
      },

      giftId(){
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        return urlParams.get('giftId')
      },

      newGift(){
        this.title = ''
        this.price = ''
        this.raffles = ''
        this.giftImage = ''
        this.$router.push({name: 'gifts-edit'})
      },
      unlimit(){
        if(this.unlimited){
          this.limit = -1
        }
      },
      rotatePhoto(){
        this.rotation += 90
      },
      onButtonClick() {
        this.isSelecting = true
        window.addEventListener('focus', () => {
          this.isSelecting = false
        }, { once: true })

        this.$refs.picture.click()
      },

      photoSelected(e) {
        this.cropImageURL = URL.createObjectURL(e.target.files[0])
        this.showClipper = true
        this.giftImage = ''
      },

      async getSelectedGift(){
        await this.getGift({id:this.giftId()})
        this.title = this.gift[0].title
        this.category = this.gift[0].category
        this.$refs.priceInput.$el.getElementsByTagName('input')[0].value = this.gift[0].price*100
        this.price = this.gift[0].price*100
        this.raffles = this.gift[0].raffle
        this.active = this.gift[0].active

        switch (this.gift[0].plan) {
          case 2:
            this.plan = {name: 'Premium', val: "2"}
            break;
          default:
            this.plan = {name: 'Básico', val: "1"}
        }

        if(this.gift[0].limit == -1){ this.unlimited = true }

        this.limit = this.gift[0].limit
        this.giftImage = this.gift[0].image

        this.containerKey += 1
      },

      async save(){

        if(this.$refs.form.validate()){
          // upload da foto
          const filename = new Date().getTime()+'.jpg'
          var bucket = 'tiptop/gifts/imgs'

          if(this.showClipper){ // somente se tiver selecionado nova foto

            const { canvas } = this.$refs.cropper.getResult()
            const newBlob = dataURItoBlob(canvas.toDataURL('image/jpeg', 0.8))
            let imgFile = new File([newBlob], "iFraldas.jpg") // arquivo que será enviado para o S3

            const url = await this.getUploadUrl({
              op: 'PUT',
              cdn: process.env.VUE_APP_S3_BUCKET, 
              bucket: bucket,
              filename: filename
            })

            await this.newUploadFile({
              op: 'PUT',
              url: url,
              formData: imgFile
            })

            this.showClipper = false
          }

          var plano = '1'
          if(this.plan.val == undefined){ plano = this.plan }else{ plano = this.plan.val }

          if(this.bannerUploaded || this.giftId() != null){ // novo presente com imagem cadastrada OU update

            var self = this
            var payload = {
              title: this.title,
              plan: plano,
              category: this.category,
              price: this.price.trim().replace(/[^0-9]/g, '')/100,
              raffle: this.raffles,
              limit: this.limit,
              active: this.active
            }

            if(self.giftId() != null){ // edição
              payload._id = self.giftId()

              if(self.giftImage == ''){
                payload.image = '/tiptop/gifts/imgs/'+filename
              }else{
                payload.image = self.giftImage
              }

              await self.updateGift(payload)
              self.getSelectedGift()
            }else{ // salvar novo
              payload.image = '/tiptop/gifts/imgs/'+filename
              await self.registerGift(payload)
              self.$router.push({name: 'gifts-list'})
              // adiciona o ID na URL
              // const urlParams = new URLSearchParams(window.location.search)
              // urlParams.set('giftId', self.gId);
              // window.location.search = urlParams
              // self.getSelectedGift()
            }

          }

        }
      },

    },
    mounted(){
      if(this.giftId() != null){
        this.getSelectedGift()
      }
    }
  }

</script>
<style>
.cropper {
	height: 100%;
	width: 100%;
	background: #DDD;
}
</style>
