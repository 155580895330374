var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.delConfirm),callback:function ($$v) {_vm.delConfirm=$$v},expression:"delConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v("Excluir o presente \""+_vm._s(_vm.giftName)+"\"?")]),_c('v-card-text',{staticClass:"mt-4"},[_c('span',[_vm._v("Essa operação não poderá ser desfeita")]),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){_vm.delConfirm = false}}},[_vm._v("cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.delGift(_vm.giftId)}}},[_vm._v("Confirmar")])],1)],1)],1)],1),_c('v-card',[_c('v-row',[_c('v-col',{staticClass:"text-end"},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","depressed":"","to":{ name: 'gifts-edit' }}},[_vm._v("+ Novo presente ")])],1)],1),_c('v-data-table',{staticClass:"mt-4 elevation-0",attrs:{"items":_vm.gifts,"header-props":{ 'sortByText':'Ordenar por' },"options":_vm.pagination,"page":_vm.pagination.current,"server-items-length":_vm.totalGifts,"headers":_vm.headers,"sort-by":['category','name'],"no-data-text":"Nenhum presente cadastrado","footer-props":{ 'items-per-page-options': [100, 200, 300], 'items-per-page-text':'itens' },"loading-text":"carregando"},on:{"update:options":function($event){_vm.pagination=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "current", $event)}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"36px"}},[_c('v-img',{attrs:{"src":("" + _vm.imgPath + (item.image)),"aspect-ratio":1/1}})],1)]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [(item.plan=='1')?_c('span',[_vm._v("Básico")]):_vm._e(),(item.plan=='2')?_c('span',[_vm._v("Premium")]):_vm._e()]}},{key:"item.limit",fn:function(ref){
var item = ref.item;
return [(item.limit=='-1')?_c('span',[_vm._v("∞")]):_c('span',[_vm._v(_vm._s(item.limit))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.price)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-4",attrs:{"x-small":"","fab":"","depressed":"","to":{ name: 'gifts-edit', query: { giftId: item._id }}}},[_c('v-icon',[_vm._v("fas fa-pencil-alt")])],1),_c('v-btn',{staticClass:"mr-4",attrs:{"x-small":"","fab":"","depressed":""},on:{"click":function($event){return _vm.deleteConfirm(item)}}},[_c('v-icon',[_vm._v("fas fa-trash")])],1)]}}])})],1),_c('ErrorMessage'),_c('SuccessMessage')],1)}
var staticRenderFns = []

export { render, staticRenderFns }