<template lang="pug" src="./Purchases.pug">

</template>
<script>

import { mapActions } from 'vuex'

  export default{
    name: 'Purchases',
    props: ['withdrawId'],
    data: () => ({
      dataTableLoading: false,
      expanded: [],
      singleExpand: false
    }),

    computed: {
      purchases() {
        return this.$store.state.withdraws.purchases
      },
      headers(){
        return [
          { text: 'Comprado em', value: 'createdAt' },
          { text: 'Nome', value: 'name' },
          { text: 'Bruto', value: 'total', sortable: false },
          { text: 'iFraldas', value: 'ifraldasCommissionValue', sortable: false },
          { text: 'Gateway', value: 'gatewayCommissionValue', sortable: false },
          { text: 'Líquido', value: 'redeemable', sortable: false }
        ]
      }
    },

    methods: {
      ...mapActions(['getListTransactions']),
      async getList(){
        await this.getListTransactions({ withdrawId: this.withdrawId })
        console.log('Retorno: '+JSON.stringify(this.purchases,null,2))
      },
      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
    },
    mounted(){
      this.getList()
    },
    watch: {
      withdrawId(){
        console.log('novo id: '+this.withdrawId)
        this.getList()
      }
    }
  }
</script>
<style>

    /* CSS corretivo: no Safari Mobile a tabela desconfigurava após expandir algum item */
    tr.v-data-table__expanded__content{
      box-shadow: none !important;
      background-color: #F9F9F9;
    }

    table tbody .v-data-table__mobile-table-row{
      display: block !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content{
      display: block !important;
      width: 100% !important;
    }
    div.correctExpandableMobile tr.v-data-table__expanded__content td{
      display: table;
      width: 100% !important;
    }

</style>
