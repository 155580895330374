import api from '@/services/api/transactions'
import * as types from '@/store/mutation-types'
import { handleError } from '@/utils/utils.js'

const getters = {
  transactions: state => state.transactions,
  totalTransactions: state => state.totalTransactions,
}

const actions = {

  getTransactions({ commit }, payload) {

    //console.log('Aqui',JSON.stringify(payload,null,2))
    return new Promise((resolve, reject) => {
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getTransactions(payload)
        .then(response => {
          //console.log('getTransactions response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.TRANSACTIONS, response.data.docs)
            commit(types.TOTAL_TRANSACTIONS, response.data.totalDocs)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          //console.log('getTransactions error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  },

}

const mutations = {
  [types.TRANSACTIONS](state, value) {
    state.transactions = value
  },
  [types.TOTAL_TRANSACTIONS](state, value) {
    state.totalTransactions = value
  }
}

const state = {
  transactions: [],
  totalTransactions: 0
}

export default {
  state,
  getters,
  actions,
  mutations
}
