import api from '@/services/api/dash'
import * as types from '@/store/mutation-types'
import { handleError } from '@/utils/utils.js'

const getters = {
  dash: state => state.dash
}

const actions = {
  getDash({ commit }, payload) {

    return new Promise((resolve, reject) => {
      commit(types.DASH, [])
      commit(types.SHOW_CENTER_LOADING, true)
      api
        .getDash(payload)
        .then(response => {
          //console.log('getDash response: '+JSON.stringify(response,null,2))
          if (response.status === 200) {
            commit(types.DASH, response.data)
            commit(types.SHOW_CENTER_LOADING, false)
            resolve(true)
          }
        }).catch(error => {
          console.log('getDash error: '+JSON.stringify(error,null,2))
          commit(types.SHOW_CENTER_LOADING, false)
          handleError(error, commit, reject)
        })
    })
  }
}

const mutations = {
  [types.DASH](state, value) {
    state.dash = value
  }
}

const state = {
  dash: []
}

export default {
  state,
  getters,
  actions,
  mutations
}
