import axios from 'axios'

export default {
  starkBankPay(params){
    return axios.get(`/withdraws/admin/starkpay/${params.eventId}`)
  },

  starkBankLoan(params){
    return axios.get(`/bank/admin/starkpay/${params.id}`)
  },

  // getListTransactions(params){
  //
  //   return axios.get(`/withdraws/admin/list-transactions/${params.withdrawId}`)
  // },
  //
  //
  // updateWithdraws(payload){
  //   return axios.patch('/withdraws/admin/update', payload)
  // }
}
