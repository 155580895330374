<template lang="pug" src="./List.pug">

</template>
<script>

import { mapActions } from 'vuex'
import { buildPayloadPagination } from '@/utils/utils.js'

  export default{
    data: () => ({
      search: '',
      bucket: process.env.VUE_APP_S3_BUCKET,
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      publicPath: process.env.BASE_URL,

      delConfirm: false,
      giftId: '',
      giftName: '',
      giftImage: '',
      pagination: {
        sortBy: ['createdAt'],
        sortDesc: ['true']
      }

    }),

    computed: {

      headers(){
        return [
          { text: 'Imagem', value: 'image' },
          { text: 'Categoria', value: 'category' },
          { text: 'Nome', value: 'title' },
          { text: 'Preço', value: 'price' },
          { text: 'Rifas', value: 'raffle' },
          { text: 'Limite', value: 'limit' },
          { text: 'Plano', value: 'plan' },
          { text: 'Ações', value: 'actions' }
        ]
      },
      gifts(){
        let array = this.$store.state.gifts.gifts
        let sortedByCategory = array.sort((a, b) => (a.category > b.category) ? 1 : -1)
        return sortedByCategory
        //return this.$store.state.gifts.gifts
      },
      totalGifts(){
        return this.$store.state.gifts.totalGifts
      }
    },

    methods: {
      ...mapActions([ 'getGifts', 'deleteGift' ]),
      deleteConfirm(item){
        this.giftName = item.title
        this.giftId = item.id
        this.giftImage = item.image
        this.delConfirm = true
      },
      async delGift(id){
        this.delConfirm = false
        await this.deleteGift({ _id: id })
        this.listGifts()
      },
      async listGifts(){
        console.log('1')
        await this.getGifts()
      },
      formatPrice(value) {
        if(value == undefined){ value = 0 }
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      },
      async doSearch() {
        try {

          this.dataTableLoading = true
          console.log('2')
          await this.getGifts( buildPayloadPagination(this.pagination, this.buildSearch()) )
          this.dataTableLoading = false

        } catch (error) {
          this.dataTableLoading = false
        }
      },
      buildSearch() {
        return this.search
          ? `?key=${this.search}`
          : ''
      }
    },
    mounted(){
      this.doSearch()
    },
    watch: {
      pagination: {
      async handler() {
        try {
          // console.log(JSON.stringify(this.pagination,null,2))
          this.dataTableLoading = true
          console.log('3')
          await this.getGifts( buildPayloadPagination(this.pagination, this.buildSearch()) )
          this.dataTableLoading = false

        } catch (error) {
          this.dataTableLoading = false
        }
      },
        deep: true
      }
    }
  }
</script>
