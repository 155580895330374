import axios from 'axios'

export default {
  getEvents(params){
    if(params.query){
      return axios.get(`/events/admin/list${params.query}`, { params })
    }else {
      return axios.get(`/events/admin/list`, { params })
    }

    // events/admin/list?key=busca
  },
  getEvent(params){
    return axios.get(`/events/admin/${params.eventId}`)
  },
  getEventTransactions(params){
    return axios.get(`transactions/admin/list/${params.eventId}`)
    // events/admin/list?key=busca
  },
  updateEvent(payload){
    return axios.patch('/events/admin/update', payload)
  }
}
