export default [
  {
    path: '/events',
    name: 'events-events',
    meta: {
      requiresAuth: true,
      title: 'Eventos'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/events/Events.vue')
  },
  {
    path: '/events/event/:eventId?',
    name: 'events-event',
    meta: {
      requiresAuth: true,
      title: 'Evento'
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '@/components/events/DataTabs.vue'),
    props: true
  }
]
