<template lang="pug" src='./Register.pug'></template>
<script>

import brasil from "@/utils/brasil.json"
import { mapActions, mapGetters } from 'vuex'
import {mask} from 'vue-the-mask'
import * as types from '@/store/mutation-types'

export default{
    directives: {mask},
    data: () => ({
        routeName: '',
        value: true,
        valid: true,
        rules: {
            required: v => !!v || 'Campo obrigatório',
            email: v => !v || /.+@.+\..+/.test(v) || 'Digite um e-mail válido',
            cnpj: v => !v || /^(\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{14})$/.test(v) || 'CNPJ inválido'
        },
        // store
        name: '',
        corporateName: '',
        cnpj: '',
        address: {
            zipCode: '',
            streetName: '',
            streetNumber: '',
            additionalAddress: '',
            neighborhood: '',
            city: null,
            state: null
        },
        
        // user
        // email: '',
        // userName: '',
        // password: '',
        // userPhone: '',

        cidades: [],
        brasil,
        estados: [
            { value: null, text: "Selecione um estado" },
            { value: "AC", text: "Acre" },
            { value: "AL", text: "Alagoas" },
            { value: "AP", text: "Amapá" },
            { value: "AM", text: "Amazonas" },
            { value: "BA", text: "Bahia" },
            { value: "CE", text: "Ceará" },
            { value: "DF", text: "Distrito Federal" },
            { value: "ES", text: "Espírito Santo" },
            { value: "GO", text: "Goiás" },
            { value: "MA", text: "Maranhão" },
            { value: "MT", text: "Mato Grosso" },
            { value: "MS", text: "Mato Grosso do Sul" },
            { value: "MG", text: "Minas Gerais" },
            { value: "PA", text: "Pará" },
            { value: "PB", text: "Paraíba" },
            { value: "PR", text: "Paraná" },
            { value: "PE", text: "Pernambuco" },
            { value: "PI", text: "Piauí" },
            { value: "RJ", text: "Rio de Janeiro" },
            { value: "RN", text: "Rio Grande do Norte" },
            { value: "RS", text: "Rio Grande do Sul" },
            { value: "RO", text: "Rondônia" },
            { value: "RR", text: "Roraima" },
            { value: "SC", text: "Santa Catarina" },
            { value: "SP", text: "São Paulo" },
            { value: "SE", text: "Sergipe" },
            { value: "TO", text: "Tocantins" }
        ]
    }),
    computed: { ...mapGetters( ['storeData'] ) },
    methods: {
        ...mapActions(['registerStore', 'getStore', 'updateStore']),

        fill(){

            this.address.streetName = '...'
            this.address.neighborhood = '...'

            this.address.zipCode = this.address.zipCode.trim().replace(/[^0-9]/g, '')
            var self = this

            var request = new XMLHttpRequest();
            request.open('get', "https://api.pagar.me/1/zipcodes/"+this.address.zipCode, true)
            request.send()

            request.onload = function () {
                var data = JSON.parse(this.response)
                if(!data.erro) {
                self.address.streetName = data.street
                self.address.neighborhood = data.neighborhood
                self.address.state = data.state
                self.address.city = data.city
                
                self.$refs.numero.focus()
                }else{
                console.log('CEP não encontrado')
                this.cleanForm()
                }
            }
        },

        passwordSuggest(size = 8) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let password = ''
    
            for (let i = 0; i < size; i++) {
                const index = Math.floor(Math.random() * characters.length)
                password += characters.charAt(index)
            }
    
            this.password = password
        },

        async register(){
            
            if(this.$refs.form.validate()) {
                
                // POST /stores/admin
                let payload = {
                    name: this.name,
                    corporateName: this.corporateName,
                    cnpj: this.cnpj.trim().replace(/[^0-9]/g, ''),
                    address: {
                        zipCode: this.address.zipCode.trim().replace(/[^0-9]/g, ''),
                        streetName: this.address.streetName,
                        streetNumber: this.address.streetNumber,
                        neighborhood: this.address.neighborhood,
                        city: this.address.city,
                        state: this.address.state
                    },
                    alias: 'tiptop_'+this.cnpj.slice(-2)
                }
                if(this.address.additionalAddress !== '') { payload.address.additionalAddress = this.address.additionalAddress }
                
                // POST /users/admin/register
                // let userPayload = {
                //     name: this.userName,
                //     phone: this.userPhone.trim().replace(/[^0-9]/g, ''),
                //     email: this.email,
                //     password: this.password,
                //     role: "store",
                //     store: 'tiptop_'+this.cnpj.slice(-2)
                // }

                if( this.$route.name == 'stores-edit' ) { 
                    payload._id = this.storeData._id
                    await this.updateStore(payload)
                    this.$router.push({name: 'stores-list'})
                }else{
                    let storeResponse = await this.registerStore(payload)
                    if (storeResponse === true) {
                        // await this.registerUser(userPayload)
                        this.$router.push({name: 'stores-list'})
                    }
                }

            }
            
        }

    },
    async mounted(){
        this.routeName = this.$route.name
        if( this.$route.name == 'stores-edit' && this.storeData == null) { 
            const url = window.location.href
            const parts = url.split("/")
            const id = parts[parts.length - 1]
            await this.getStore({ _id: id })
        }
        if(this.storeData) { 
            console.log(JSON.stringify(this.storeData,null,4))

            this.name = this.storeData.name ?? this.name
            this.corporateName = this.storeData.corporateName ?? this.corporateName

            const { cnpj, alias } = this.storeData
            if(cnpj) {
                this.cnpj = cnpj.length < 14 ? cnpj+alias.slice(-2) : cnpj
            }
            
            this.address.zipCode = this.storeData.address?.zipCode ?? this.address.zipCode
            this.address.streetName = this.storeData.address?.streetName ?? this.address.streetName
            this.address.streetNumber = this.storeData.address?.streetNumber ?? this.address.streetNumber
            this.address.additionalAddress = this.storeData.address?.additionalAddress ?? this.address.additionalAddress
            this.address.neighborhood = this.storeData.address?.neighborhood ?? this.address.neighborhood
            this.address.city = this.storeData.address?.city ?? this.address.city
            this.address.state = this.storeData.address?.state ?? this.address.state
            
            // this.email = this.storeData.user?.email ?? this.email
            // this.userName = this.storeData.user?.name ?? this.userName
            // this.userPhone = this.storeData.user?.phone ?? this.userPhone
        }
        if( this.$route.name == 'stores-register') { this.passwordSuggest() }else{ this.password = '********' }
        
    },
    beforeDestroy() { 
        this.$store.commit(types.STORE_DATA, null)
    },
    watch: {
    'address.state': function () {
      this.cidades = brasil[this.address.state].cidades
    },
    storeData(val) {
        console.log('val', val)
    }
  },

}

</script>