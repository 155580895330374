<template lang="pug" src="./Edit.pug">

</template>
<script>

import { mapActions } from 'vuex'
import {mask} from 'vue-the-mask'
  export default{

    directives: {mask},
    data: () => ({
      bucket: process.env.VUE_APP_S3_BUCKET,
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      publicPath: process.env.BASE_URL,

      name: '',
      influencer: false,
      email: '',
      phone: '',
      password: '',
      disablePass: true,
      disableFields: true,

      isPartner: false,
      isPromoter: false,

      rules: {
        required: value => !!value || 'Campo obrigatório',
        email: v => !v || /.+@.+\..+/.test(v) || 'Digite um e-mail válido',
        twoWords: value => (!!value && (/(\w.+\s).+/).test(value)) || 'Digite o nome completo',
        // twoWords: value => (!!value && (/\w+\s+\w/).test(value)) || 'Digite seu nome completo'
      }

    }),
    computed: {
      partner(){
        return this.$store.state.partners.partner
      },
      pId(){
        return this.$store.state.partners.pId
      },
      existentPartner() {
        return this.$store.state.partners.partners
      }
    },
    methods: {
      ...mapActions(['registerPartner', 'updatePartner', 'getPartner', 'getExistingPartners']),

      async checkMail() {
        // só checa se não estiver editando
        if(this.partnerId() == null) {
          this.isPartner = false
          this.isPromoter = false
          this.name = ''
          this.phone = ''
          this.disablePass = true
          await this.getExistingPartners({ key: this.email })
        }
      },

      generatePass() {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyz"
        var passwordLength = 5
        var password = ""

        for (var i = 0; i <= passwordLength; i++) {
          var randomNumber = Math.floor(Math.random() * chars.length);
          password += chars.substring(randomNumber, randomNumber +1);
        }
        return password
      },

      partnerId(){
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        return urlParams.get('partnerId')
      },
      newPartner(){
        this.name = ''
        this.email = ''
        this.phone = ''
        this.$refs.form.reset()

        this.disablePass = false
        if(this.password == '') { this.password = this.generatePass() }

        window.history.replaceState(null, null, window.location.href.split("?")[0])
      },

      async getSelectedPartner(){
        await this.getPartner({ _id: this.partnerId() })
        this.name = this.partner.name
        this.email = this.partner.email
        this.influencer = (this.partner.influencer == true) ? true : false
        this.phone = this.partner.telefone.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')
        this.password = '******'
        this.disablePass = true
        this.disableFields = false
      },

      async save(){

        if(this.$refs.form.validate()){

          var self = this
          var payload = {
            role: "partner",
            name: this.name,
            email: this.email,
            telefone: this.phone.replace(/\D/g, "")
          }

          if(this.isPromoter) { // se usuário já for um promoter existente
            payload._id = this.existentPartner[0]._id
            await self.updatePartner(payload)
            return
          }

          if(self.partnerId() == null && !this.isPromoter) { // é um novo usuário
            payload.password = this.password
          }

          if(self.influencer == true) { payload.influencer = true } // é influenciadora digital

          if(self.partnerId() != null || this.isPromoter){ // edição
            payload._id = self.partnerId()
            await self.updatePartner(payload)
          }else{ // salvar novo
            await self.registerPartner(payload)
            // adiciona o ID na URL
            window.history.replaceState(null, null, window.location.href+`?partnerId=${self.pId}`)
            this.disableFields = true
          }

        }

      }
    },
    mounted(){
      if(this.partnerId() != null){
        this.getSelectedPartner()
      }
    },
    watch: {
      existentPartner(newVal) {
        if(newVal.length>0){
          console.log(newVal)
          this.disableFields = true
          this.name = newVal[0].name
          this.phone = newVal[0].telefone
          this.password = '******'
          switch (newVal[0].role) {
            case 'partner': this.isPartner = true
              break
            case 'promoter': this.isPromoter = true
              break
            default: break
          }
        }else {
          this.disablePass = false
          this.disableFields = false
          if(this.password == ''){ this.password = this.generatePass() }
        }
      }
    }
  }

</script>
